import React from "react";
import theme from "theme";
import { Theme, Link, Image, Em, Strong, Text, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Dropify.net for Creators Becoming Founders
			</title>
			<meta name={"description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:title"} content={"Dropify.net for Creators Becoming Founders"} />
			<meta property={"og:description"} content={"Be part of the movement. We build products and offer services for creators becoming founders. Let us help you build authentic and sustainable income for your creative business."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/cover.png?v=2021-12-12T04:10:19.294Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default-removebg-preview.png?v=2021-12-11T03:01:53.455Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section margin="0 0 0 0" padding="16px 0px 16px 0px" box-sizing="border-box" quarkly-title="Header">
			<Stack md-justify-content="space-between">
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/index"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem width="50%" display="block" quarkly-title="Menu" md-width="25%">
					<Override slot="StackItemContent" align-items="center" justify-content="center" md-justify-content="flex-end" />
					{"        "}
					<Components.BurgerMenu>
						<Override slot="icon-open" md-position="fixed" md-top="24px" md-right="calc(4% + 4px)" />
						<Override
							slot="menu"
							display="flex"
							md-left={0}
							md-width="100%"
							padding="0px 0 0px 0"
							lg-transform="translateY(0px) translateX(0px)"
							lg-transition="transform 400ms ease 0s"
							md-position="fixed"
							md-top={0}
							md-height="100%"
						>
							<Override
								slot="item"
								text-transform="uppercase"
								text-align="center"
								padding="8px 20px 8px 20px"
								md-padding="16px 40px 16px 40px"
							/>
							<Override slot="item-404" lg-display="none" display="none" />
							<Override slot="item-index" display="none" lg-display="none" />
							<Override
								slot="link"
								hover-opacity="1"
								letter-spacing="0.5px"
								text-decoration-line="initial"
								transition="opacity .15s ease 0s"
								md-active-opacity="1"
								text-transform="initial"
								md-font="16px/24px sans-serif"
								font="--base"
								md-transition="opacity .15s ease 0s"
								md-hover-opacity="1"
								color="--dark"
								opacity=".5"
								md-color="--dark"
								md-opacity=".5"
							/>
							<Override
								slot="link-active"
								opacity="1"
								color="--primary"
								cursor="default"
								md-opacity="1"
								md-cursor="default"
							/>
						</Override>
						<Override slot="icon,icon-close" category="md" icon={MdMenu} />
						<Override slot="icon" category="md" icon={MdMenu} size="36px" />
						<Override
							slot="menu-open"
							md-justify-content="center"
							md-top={0}
							md-bottom={0}
							md-display="flex"
							md-flex-direction="column"
							md-align-items="center"
						/>
					</Components.BurgerMenu>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0">
			<Stack>
				{"    "}
				<StackItem width="100%" display="flex">
					<Override
						slot="StackItemContent"
						justify-content="center"
						height="480px"
						background="radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
						md-height="360px"
						flex-direction="column"
						align-items="center"
					/>
					{"        "}
					<Text
						sm-text-align="center"
						sm-width="80%"
						text-align="center"
						padding="0px 16px 0px 16px"
						color="--light"
						font="--headline1"
						margin="16px 0px 0px 0px"
					>
						404
					</Text>
					<Text
						font="--lead"
						color="--light"
						margin="10px 0px 35px 0px"
						sm-text-align="center"
						sm-width="80%"
						opacity="0.7"
						text-align="center"
						padding="0px 16px 0px 16px"
					>
						This is not the page you're looking for. Move along, move along.
					</Text>
					<Link
						href="/"
						font="--base"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						letter-spacing="0.5px"
						hover-transform="translateY(-4px)"
						text-decoration-line="initial"
						color="--darkL2"
						background="--color-light"
						padding="12px 24px 12px 24px"
					>
						Home Page
					</Link>
					{"    "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Section
			box-sizing="border-box"
			quarkly-title="Header"
			justify-content="center"
			margin="0 0 0 0"
			padding="16px 0 16px 0"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
			/>
			<Stack>
				{"    "}
				<StackItem
					quarkly-title="Logo"
					display="flex"
					md-width="50%"
					md-display="block"
					xl-width="25%"
					width="25%"
				>
					<Override slot="StackItemContent" align-items="center" justify-content="flex-start" md-justify-content="flex-start" />
					{"        "}
					<LinkBox
						flex-direction="row"
						justify-content="flex-start"
						margin="0px 0px 0px 16px"
						md-margin="0px 0px 0px 0"
						sm-align-items="center"
						href="/index"
					>
						<Image src="https://uploads.quarkly.io/61b4130673e4880020a7150f/images/default2.png?v=2021-12-15T18:43:13.474Z" display="block" width="70px" height="70px" />
						<Text margin="0px 0px 0px 0px" font="--headline3">
							<Strong>
								<Em>
									Dropify
								</Em>
							</Strong>
						</Text>
					</LinkBox>
					{"    "}
				</StackItem>
				<StackItem quarkly-title="Logo" md-width="50%" width="50%" display="flex">
					<Override slot="StackItemContent" md-justify-content="flex-end" align-items="center" justify-content="center" />
					<Link
						opacity="0.6"
						font="--base"
						href="/terms"
						color="#000000"
						text-decoration-line="initial"
					>
						Terms of Use & Privacy Policy
					</Link>
					{"        "}
				</StackItem>
				<StackItem
					width="25%"
					md-display="block"
					sm-display="block"
					md-width="100%"
					display="flex"
					quarkly-title="Side"
				>
					<Override
						slot="StackItemContent"
						align-items="center"
						justify-content="flex-end"
						flex-direction="row"
						md-justify-content="center"
						sm-justify-content="center"
					/>
					{"   "}
				</StackItem>
				{"    "}
			</Stack>
		</Section>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script async={true} place={"endOfHead"} rawKey={"61b58c1050b03822215b2eac"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-MV36QFQ');"}
			</script>
			<script place={"endOfHead"} rawKey={"6246a896ffca0fbab6cc4994"}>
				{"document.addEventListener(\"DOMContentLoaded\",(e)=>{const ttclid=new URLSearchParams(window.location.search).get('ttclid');if(localStorage.getItem('ttclid')&&!ttclid){return}localStorage.setItem('ttclid',ttclid)})"}
			</script>
		</RawHtml>
	</Theme>;
});